import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityData } from '../../store/activitiesSlice.js';
import Modal from '../UI/Modal';
import { setUser } from '../../store/userSlice';
import { supabase } from '../../supabaseClient';
import Database from '../../utils/Database.js';

import SkillsList from '../skill/SkillsList.js';
import WeekTracker from '../WeekTracker.js';
import { getXpNeeded, getXpOffset, setAllSkills } from '../../store/skillSlice';
import ActivityTracker from '../activity/ActivityTracker.js';
import RecentActivityTracker from '../activity/RecentActivityTracker.js';
import BasicPage from './page-types/BasicPage.js';
import { Routes, Route } from 'react-router-dom';
import SkillPage from './SkillPage.js';
import DeviceContext from '../../context/DeviceContext.js';
import ActivitiesPage from './ActivitiesPage.js';

/**
 * Dashboard
 *
 * This is the main page of the app.
 */
function Dashboard(props) {
    const currentSkillName = useSelector(state => state.skill.name);
    const currentSkillXp = useSelector(state => state.skill.xp);
    const currentSkillLevel = useSelector(state => state.skill.level);
    const currentSkillXpNeeded = useSelector(state => state.skill.xpNeeded);
    const currentSkillXpOffset = useSelector(state => state.skill.xpOffset);
    const currentSkillTotalTime = useSelector(state => state.skill.totalTime);
    const currentSkillCurrentTime = useSelector(state => state.skill.currentTime);
    const currentSkillDifficulty = useSelector(state => state.skill.difficulty);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const isFirstLogin = useSelector(state => state.user.isFirstLogin);
    const [isLoading, setIsLoading] = useState(false);
    const skills = useSelector(state => state.skill.allSkills);
    const [error, setError] = useState(null);
    const isMobile = useContext(DeviceContext);
    const [showWelcomeModal, setShowWelcomeModal] = useState(true);
    const [showCreateSkillModal, setShowCreateSkillModal] = useState(props.showCreateModal);

    // useEffect(() => {
    //     console.log('Easy hours ', getHoursToLevel(100, 'easy'));
    //     console.log('Medium hours ', getHoursToLevel(100, 'medium'));
    //     console.log('Hard hours', getHoursToLevel(100, 'hard'));
    //     console.log('expert hours', getHoursToLevel(100, 'expert'));
    // }, []);

    // /**
    //  * This is used while developing to see how many hours
    //  * total it takes to get to a certain level.
    //  *
    //  * @param {int} level
    //  * @param {string} difficulty
    //  * @returns {int}
    //  */
    // function getHoursToLevel(level = 100, difficulty = 'easy')
    // {
    //     let hoursToLevel = 0;
    //     const xpNeeded = getXpNeeded(level, difficulty);
    //     hoursToLevel += xpNeeded / 60 / 60;

    //     return Math.floor(hoursToLevel);
    // }

    /**
     * Getting user data if not already set in state
     * and fetching skills.
     */
    useEffect(() => {
        if (user) {
            fetchSkills();
            dispatch(getActivityData(user));
        }
    }, [dispatch, user, isFirstLogin]);

    /**
     * This will update the skill currently
     * being tracked in the skills array.
     */
    const updateTrackedSkill = () => {
        const newSkills = skills.map(skill => {
            if (skill.name === currentSkillName) {
                return {
                    ...skill,
                    level: currentSkillLevel,
                    totalTime: currentSkillTotalTime,
                    currentTime: currentSkillCurrentTime,
                    xp: currentSkillXp,
                    xpNeeded: currentSkillXpNeeded,
                    xpOffset: currentSkillXpOffset
                };
            }

            return skill;
        });

        dispatch(setAllSkills(newSkills));
    };

    /**
     * Updating the currently tracked
     * skill.
     */
    useEffect(() => {
        updateTrackedSkill();
    }, [currentSkillXp, currentSkillLevel, currentSkillName, currentSkillXpNeeded, currentSkillXpOffset, currentSkillTotalTime, currentSkillCurrentTime]);

    /**
     * This will fetch the users skills and set them in state.
     */
    const fetchSkills = async () => {
        setIsLoading(true);

        try {
            const { data, error } = await Database.getAllSkills(user);
            setIsLoading(false);

            if (error) {
                setError({ hasError: true, message: error.message });
                throw new Error('Something went wrong with getting all skills');
            } else {
                formatAndSetSkills(data);
            }
        } catch (error) {
            console.error(error.message);
            setError(error.message);
        }
    };

    /**
     * This will format the skill data
     * received from the db.
     */
    const formatSkill = (skill) => {
        const skillLevel = skill["level"];
        return {
            name: skill.name,
            level: skillLevel,
            totalTime: skill["time_tracked"],
            xpOffset: getXpOffset(skillLevel, skill.difficulty),
            xpNeeded: getXpNeeded(skillLevel, skill.difficulty),
            xp: skill.xp,
            id: skill.id,
            difficulty: skill.difficulty
        };
    }

    /**
     * This will format and set the skills to the state.
     */
    const formatAndSetSkills = (skills) => {
        const formattedSkills = skills.map(skill => formatSkill(skill));

        dispatch(setAllSkills(formattedSkills));
    }

    function createSkillModalCloseCallback() {
        setShowCreateSkillModal(false);
        if (isFirstLogin && user) {
            (async function () {
                await Database.updateFirstLogin(user.id);
            })();
        }
    }

    return (
        <Routes>
            <Route
                path="/activities/:skillId?/:timeframe?"
                element={
                    <ActivitiesPage fetchSkills={fetchSkills} />
                }
            />
            <Route
                path="skill/:skillId"
                element={
                    <SkillPage
                        trackedTime={props.formattedTime}
                        startTimer={props.startTimer}
                        stopTimer={props.stopTimer}
                        isTracking={props.isTracking}
                        loading={props.loading}
                        skills={skills}
                        fetchSkills={fetchSkills}
                    />
                }
            />
            <Route
                path="/*"
                element={
                    <BasicPage className="dashboard">
                        {isFirstLogin &&
                            <Modal
                                title='Welcome!'
                                body="It looks like this is your first time logging in. Add your first skill to get started."
                                buttonText='Get started'
                                show={showWelcomeModal}
                                confirmAction={() => {
                                    setShowWelcomeModal(false)
                                    setShowCreateSkillModal(true)
                                }}
                                hideClose={true}
                                close={() => {}}
                                className='welcome-modal'
                                modalID='first login'
                            />
                        }
                        {isMobile && <>
                            <WeekTracker />
                            <SkillsList
                                skills={skills}
                                fetchSkills={fetchSkills}
                                showCreateSkillModal={showCreateSkillModal}
                                createSkillModalCloseCallback={createSkillModalCloseCallback}
                                setShowCreateSkillModal={setShowCreateSkillModal}
                            />
                            <RecentActivityTracker />
                        </>}
                        {!isMobile && <>
                            <SkillsList
                                skills={skills}
                                fetchSkills={fetchSkills}
                                showCreateSkillModal={showCreateSkillModal}
                                createSkillModalCloseCallback={createSkillModalCloseCallback}
                                setShowCreateSkillModal={setShowCreateSkillModal}
                            />
                            <div className="right-side-container">
                                <WeekTracker />
                                <RecentActivityTracker />
                            </div>
                        </>}
                    </BasicPage>
                }
            >
            </Route>
        </Routes>
    );
}

export default Dashboard;