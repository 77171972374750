import React, { useState, useEffect } from 'react';
import Modal from '../UI/Modal';
import InputGroup from '../UI/forms/InputGroup';
import { useDispatch } from 'react-redux';
import { setSkill, setCurrentTime, xpThunk, setLoading, updateTime, insertActivity } from '../../store/skillSlice';

/**
 * AddTimeModal
 */
function AddTimeModal(props) {
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [error, setError] = useState(null);
    const [formTouched, setFormTouched] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!formTouched) {
            return;
        }

        if (!hours && !minutes) {
            setError('Please enter a valid time.');
            return;
        }

        if (hours > 100 || minutes > 60) {
            setError("Can't add more than 100 hours or 60 minutes at a time.");
            return;
        }

        setError(null);
    }, [hours, minutes, formTouched]);

    /**
     * This will save the changes
     * to the database.
     *
     * @param {object} e
     * @returns {void}
     */
    const saveChanges = (e) => {
        e.preventDefault();

        if (error) {
            return;
        }

        addTime(e);

        setHours('');
        setMinutes('');
    }

    /**
     * This will handle updating
     * the skill in the database.
     *
     * @param {object} e
     * @returns {void}
     */
    async function addTime(e) {
        e.preventDefault();

        dispatch(setSkill(props.skill));
        dispatch(setLoading(true));

        const timeInSeconds = (hours * 3600) + (minutes * 60);

        dispatch(setCurrentTime(timeInSeconds));

        /**
         * Updating the xp 3 times to ensure
         * the level is checked and incremented
         * if necessary.
         */
        for (var i = 0; i <= 3; i++) {
            dispatch(xpThunk(timeInSeconds - 3 + i));
        }

        dispatch(updateTime());

        dispatch(insertActivity());

        dispatch(setLoading(false));

        props.close();
    }

    function handleFocus(e) {
        setFormTouched(true);
    }

    return (
        <Modal
            {...props}
            title={'Add time for ' + props.skill.name}
            buttonText={'Save'}
            modalID={props.skill.name}
            confirmAction={saveChanges}
        >
            <InputGroup
                id='hours'
                label="Hours"
                name="hours"
                value={hours}
                type="number"
                onChange={(e) => setHours(e.target.value)}
                onFocus={handleFocus}
                placeholder='0'
            />
            <InputGroup
                id='minutes'
                label="Minutes"
                name="minutes"
                value={minutes}
                type="number"
                onChange={(e) => setMinutes(e.target.value)}
                onFocus={handleFocus}
                placeholder='0'
            />
            {error && <p className="error">{error}</p>}
        </Modal>
    );
}

export default AddTimeModal;