import React, { useEffect, useState } from 'react';
import SkillCard from './SkillCard';
import PlusCircle from '../../images/plus-circle.svg';
import { H2 } from '../UI/headings';
import Button from '../UI/Button';
import CreateSkillModal from '../modals/CreateSkillModal';

/**
 * SkillsList
 */
function SkillsList(props) {
    /**
     * Creating the list.
     */
    const skillsList = props.skills.map(skill => {
        return (
            <SkillCard
                skill={skill}
                key={skill.id}
                fetchSkills={props.fetchSkills}
            />
        );
    });

    return (
        <div className="skills-list">
            <div className="heading-container">
                <H2>Your Skills</H2>
                <Button
                    className="add-skill"
                    onClick={() => props.setShowCreateSkillModal(true)}
                >
                    <img src={PlusCircle} alt="Plus Circle" className="plus-circle" />
                </Button>
                <CreateSkillModal
                    fetchSkills={props.fetchSkills}
                    show={props.showCreateSkillModal}
                    close={() => props.createSkillModalCloseCallback()}
                    cancelAction={() => props.createSkillModalCloseCallback()}
                />
            </div>
            {props.skills.length === 0 && <p className="bg-ele padding-4 margin-bottom-1 border-radius-2">Your skills will appear here once created.</p>}
            {skillsList}
        </div>
    );
}

export default SkillsList;